/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/sticky-header/sticky-header').then((m) => { m.default(); });
	import('../components/mobile-nav/mobile-nav').then((m) => { m.default(); });
	import('../components/form-switcher/form-switcher').then((m) => { m.default(); });
	if (document.querySelector('.v-live-music')) {
		import('../components/live-music/live-music').then((m) => { m.default(); });
	}

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow   = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const checkboxes = document.querySelectorAll('[type="checkbox"]');
	[...checkboxes].forEach((checkbox) => {
		const checkboxParent = checkbox.parentNode;
		if (checkboxParent.classList.contains('v-form__checkbox')) {
			return;
		}

		const wrapper = document.createElement('span');
		const box   = document.createElement('span');
		wrapper.classList.add('v-form__checkbox');
		box.classList.add('v-form__checkbox-box');
		checkboxParent.insertBefore(wrapper, checkbox);
		wrapper.appendChild(checkbox);
		wrapper.appendChild(box);
		checkbox.classList.add('v-form__checkbox-field');

		const label = checkboxParent.querySelector('.wpcf7-list-item-label');
		if (label) {
			wrapper.appendChild(label);
		}

		if (wrapper.parentElement.nodeName.toLowerCase() === 'label') {
			wrapper.parentElement.classList.add('v-form__checkbox-label');

			if (wrapper.closest('.v-form__checkbox-buttons')) {
				wrapper.parentElement.classList.add('v-button', 'v-button--outline');
			}
		}
	});

	const radios = document.querySelectorAll('[type="radio"]');
	[...radios].forEach((radio) => {
		const radioParent = radio.parentNode;
		if (radioParent.classList.contains('v-form__radio')) {
			return;
		}

		const wrapper = document.createElement('span');
		const box   = document.createElement('span');
		wrapper.classList.add('v-form__radio');
		box.classList.add('v-form__radio-box');
		radioParent.insertBefore(wrapper, radio);
		wrapper.appendChild(radio);
		wrapper.appendChild(box);
		radio.classList.add('v-form__radio-field');

		const label = radioParent.querySelector('.wpcf7-list-item-label');
		if (label) {
			wrapper.appendChild(label);
		}

		if (wrapper.parentElement.nodeName.toLowerCase() === 'label') {
			wrapper.parentElement.classList.add('v-form__radio-label');
		}
	});

	const fileUploads = document.querySelectorAll('.v-form__file-upload');
	[...fileUploads].forEach((fileUpload) => {
		const input = fileUpload.querySelector('input[type="file"]');

		const fileParent = input.parentNode;
		if (fileParent.classList.contains('v-form__file')) {
			return;
		}

		const wrapper = document.createElement(input.closest('label') ? 'span' : 'label');
		const button   = document.createElement('span');
		wrapper.classList.add('v-form__file');
		button.classList.add('v-form__file-button', 'v-button', 'v-button--outline');
		button.innerText = 'Upload File';
		fileParent.insertBefore(wrapper, input);
		wrapper.appendChild(input);
		wrapper.appendChild(button);
		input.classList.add('v-form__file-field');

		const previewWrap = document.createElement('div');
		previewWrap.classList.add('v-form__file-preview-wrap');
		previewWrap.innerText = 'Your file was uploaded successfully!';
		const previewButton = document.createElement('button');
		previewButton.classList.add('v-form__file-preview-button');
		previewButton.setAttribute('type', 'button');
		const preview = document.createElement('img');
		preview.classList.add('v-form__file-preview');
		preview.setAttribute('alt', 'File preview');
		const previewText = document.createElement('span');
		previewText.classList.add('sr-only');
		previewText.innerText = 'Remove file';
		previewButton.appendChild(previewText);
		previewButton.appendChild(preview);
		previewWrap.prepend(previewButton);
		fileUpload.appendChild(previewWrap);

		input.addEventListener('change', () => {
			if (input.files.length) {
				button.innerText = 'Upload Complete';
				button.classList.add('v-form__file-button--complete');
				preview.src = URL.createObjectURL(input.files[0]);
				previewWrap.classList.add('v-form__file-preview-wrap--visible');
			} else {
				button.innerText = 'Upload File';
				button.classList.remove('v-form__file-button--complete');
				previewWrap.classList.remove('v-form__file-preview-wrap--visible');
			}
		});

		previewButton.addEventListener('click', () => {
			input.value = '';
			input.dispatchEvent(new Event('change'));
		});
	});

	const inputs = document.querySelectorAll('input');
	[...inputs].forEach((input) => {
		input.removeAttribute('size');
	});

	const submitFields = document.querySelectorAll('.v-form__field--submit');
	[...submitFields].forEach((field) => {
		const form = field.closest('.wpcf7-form');
		const responseBox = form.querySelector('.wpcf7-response-output');
		field.appendChild(responseBox);
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import(/* webpackExports: "listen" */ 'quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
